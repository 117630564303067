import { Link } from 'gatsby'
import { useStyletron } from 'styletron-react'
import { slugify } from '../utils'
import React from 'react'
import { ourColors } from './Colors'
import { LabelLarge, ParagraphLarge } from 'baseui/typography'

interface ListCardProps {
  url: string
  imageUrl?: string
  title: string
  timeString?: string
  bodyContent?: string
  category?: string
}

export const ListCard = ({
  url,
  imageUrl,
  title,
  timeString,
  bodyContent,
  category
}: ListCardProps) => {
  const [css] = useStyletron()
  const titleSlug = slugify(title)
  return (
    <Link className={css({ display: 'block', color: 'unset', textDecoration: 'none' })} to={url}>
      {imageUrl && <img src={imageUrl} className={css({ marginBottom: 0, display: 'block' })} />}
      <div
        className={css({
          paddingTop: '20px',
          paddingLeft: '16px',
          paddingRight: '16px',
          backgroundColor: ourColors.lightGrey,
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          maxWidth: '627px'
        })}
      >
        <div
          className={css({
            paddingBottom: '6px',
            textDecorationLine: 'underline',
            textDecorationColor: ourColors.copyGrey
          })}
          data-testid={`${titleSlug}-heading`}
        >
          <div className="noAnalytics">
            <LabelLarge>{title}</LabelLarge>
          </div>
        </div>
        {timeString && (
          <div
            data-testid={`${titleSlug}-datetime`}
            className={css({
              fontStyle: 'italic'
            })}
          >
            <ParagraphLarge marginBottom={0}>{timeString}</ParagraphLarge>
          </div>
        )}
        <div data-testid={`${titleSlug}-notes`} className={css({ paddingBottom: '10px' })}>
          <div className="noAnalytics">
            <ParagraphLarge marginBottom={0}>{bodyContent}</ParagraphLarge>
          </div>
        </div>
      </div>
      <div
        data-testid={`${titleSlug}-category`}
        className={css({
          marginBottom: '23px',
          paddingLeft: '17px',
          color: 'white',
          lineHeight: '28px',
          fontSize: '15px',
          backgroundColor: ourColors.utechPink,
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          paddingTop: '4px',
          paddingBottom: '6px'
        })}
      >
        {category || 'Other'}
      </div>
    </Link>
  )
}
