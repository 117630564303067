import React from 'react'
import { Layout, UserContext } from '../../components/Layout'
import { Heading } from '../../components/Heading'
import { Tabs, Tab } from '../../components/Tabs'
import { HeadingLevel } from 'baseui/heading'
import { useStyletron } from 'baseui'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { ourColors } from '../../components/Colors'
import { listIssues, Issue } from '../../api/cms'
import { getIssueDateString, handleErrorFromAPI, loginRedirect } from '../../utils'
import { HorizontalLine } from '../../components/Line'
import { LinkButton } from '../../components/LinkButton'
import { ParagraphLarge } from 'baseui/typography'
import { ListCard } from '../../components/ListCard'
import { ErrorNotification } from '../../components/Notification'

export const IssueListHeading = () => {
  const [css] = useStyletron()
  return (
    <Grid>
      <Cell span={[2.25, 4.25]} align={ALIGNMENT.start}>
        <Heading>Logged Issues</Heading>
      </Cell>
      <Cell span={1.75}>
        <LinkButton
          className={css({ width: '100%', display: 'inline-block' })}
          to="/issues/create-issue"
          data-testid="create-issue"
        >
          Log an issue
        </LinkButton>
      </Cell>
      <Cell span={[4, 6]}>
        <HorizontalLine
          className={css({
            marginBottom: '11px'
          })}
        />
      </Cell>
    </Grid>
  )
}

interface NonEmptyIssueListProps {
  issues: Issue[]
}

export const EmptyIssueListContent = () => {
  const NO_ISSUES_COPY =
    'You don’t have any hazards in your list. To start your list press the “Log an issue” button above.'
  return <ParagraphLarge marginTop="19px"> {NO_ISSUES_COPY} </ParagraphLarge>
}

async function getIssues(setUnresolvedIssues: Function, token: string | null) {
  await listIssues(token).then(response => {
    setUnresolvedIssues(response)
  })
}

export const NonEmptyIssueListContent = (props: NonEmptyIssueListProps) => {
  const [activeKey, setActiveKey] = React.useState('0')
  const [css] = useStyletron()

  const unresolvedEmptyCopy = (
    <>
      <ParagraphLarge>
        It looks like all your OHS issues have been resolved. Great work!
      </ParagraphLarge>
      <ParagraphLarge>
        Remember, controlling health and safety risks is an ongoing process. Employers must continue
        to:
      </ParagraphLarge>
      <ul
        className={css({
          fontSize: '18px',
          marginLeft: '30px',
          color: ourColors.copyGrey
        })}
      >
        <li>monitor and maintain control measures</li>
        <li>identify, assess and control risks</li>
        <li>consult with workers throughout the risk control process</li>
      </ul>
    </>
  )

  const resolvedEmptyCopy = (
    <>
      <ParagraphLarge>
        You have no resolved issues. If you would like to mark an issue as resolved:
      </ParagraphLarge>
      <ul
        className={css({
          fontSize: '18px',
          marginLeft: '30px',
          color: ourColors.copyGrey
        })}
      >
        <li>select the issue from your log</li>
        <li>press the actions button</li>
        <li>set as resolved</li>
      </ul>
    </>
  )

  return (
    <Tabs
      onChange={({ activeKey }) => {
        setActiveKey(activeKey.toString())
      }}
      activeKey={activeKey}
      overrides={{
        TabContent: {
          style: {
            paddingTop: '22px'
          }
        }
      }}
    >
      <Tab
        title="Unresolved"
        overrides={{
          Tab: {
            props: {
              'data-testid': 'unresolved-tab'
            }
          }
        }}
      >
        <IssueListTabContent
          emptyCopy={unresolvedEmptyCopy}
          issues={props.issues.filter(issue => issue.resolved === false)}
          title="Unresolved Issues"
        />
      </Tab>
      <Tab
        title="Resolved"
        overrides={{
          Tab: {
            props: {
              'data-testid': 'resolved-tab'
            }
          }
        }}
      >
        <IssueListTabContent
          emptyCopy={resolvedEmptyCopy}
          issues={props.issues.filter(issue => issue.resolved === true)}
          title="Resolved Issues"
        />
      </Tab>
    </Tabs>
  )
}

interface IssueListTabContentProps {
  issues: Issue[]
  title: string
  emptyCopy: React.ReactNode
}

export const IssueListTabContent = (props: IssueListTabContentProps) => {
  return (
    <HeadingLevel>
      <Heading
        overrides={{
          Block: { style: { color: ourColors.copyGrey, marginTop: 0, marginBottom: '26px' } }
        }}
        styleLevel={3}
      >
        {props.title}
      </Heading>
      <HorizontalLine />
      {props.issues.length === 0
        ? props.emptyCopy
        : props.issues.map(issue => <IssueView key={issue.id} issue={issue} />)}
    </HeadingLevel>
  )
}

interface IssueViewProps {
  issue: Issue
}

export const IssueView = ({ issue }: IssueViewProps) => {
  const timeString = getIssueDateString(issue)
  return (
    <ListCard
      url={`/issues/issue-detail?id=${issue.id}`}
      title={issue.name}
      timeString={timeString}
      bodyContent={issue.notes}
      category={issue.category}
    />
  )
}

export const IssueListContent = () => {
  const [css] = useStyletron()
  const [issues, setIssues] = React.useState<Issue[]>([])
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const { token, setUserContext } = React.useContext(UserContext)

  React.useEffect(() => {
    if (token) {
      getIssues(setIssues, token).catch(error =>
        handleErrorFromAPI(error, setUserContext, setLoadingErrorActive)
      )
    } else {
      loginRedirect()
    }
  }, [token, setUserContext])

  let content: JSX.Element
  if (loadingErrorActive) {
    content = <ErrorNotification hasError={loadingErrorActive} />
  } else if (issues.length > 0) {
    content = <NonEmptyIssueListContent issues={issues} />
  } else {
    content = <EmptyIssueListContent />
  }

  return (
    <Grid>
      <Cell span={[4, 6]} align={ALIGNMENT.start}>
        <div className={css({ width: '100%' })}>{content}</div>
      </Cell>
    </Grid>
  )
}

export const IssueList = () => {
  return (
    <HeadingLevel>
      <IssueListHeading />
      <IssueListContent />
    </HeadingLevel>
  )
}

const LayoutIssueList = () => {
  return (
    <Layout>
      <IssueList />
    </Layout>
  )
}

export default LayoutIssueList
